import { asyncComponent, indexRoute } from 'helpers/router';

export default function () { // eslint-disable-line
  return {
    path: '/',
    status: 401,
    getComponent: asyncComponent(() =>
      import(/* webpackChunkName: "backoffice-body" */ './body')),
    getIndexRoute() {
      return import(/* webpackChunkName: "backoffice-home" */ './page-home')
        .then(indexRoute);
    },
    childRoutes: [
      {
        path: 'admin',
        getChildRoutes: asyncComponent(() =>
          import(/* webpackChunkName: "backoffice-s-admin" */ './section-admin/routes'))
      },
      // *** 404
      {
        path: '*',
        getComponent: asyncComponent(() => import('containers/not-found')),
        status: 404
      }
    ]
  };
}
